@import "../../../styles/variables";

.quotes_date_dropdown {
    width: 200px;
    background: #fff;

    div[class$="-control"] {
        border: none !important;
        box-shadow: $row_shadow;
        background: none;

        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);

        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
    }

    div:hover[class$="-control"] {
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
        transition-duration: 500ms;
    }

    div {
        --tw-text-opacity: 1;
        color: rgba(55, 65, 81, var(--tw-text-opacity));
        font-size: 14px;
    }

    .css-26l3qy-menu div:not(:hover) {
        background: none !important;
    }

    .css-26l3qy-menu > div > div:hover {
        background: rgb(225, 235, 255);
    }
}
