@import "./variables";
@import "./mediaQueries";


/*Wrapper contains the Nav and the Main Content*/
.wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    .page_transition_wrapper {
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        .main {
            position: relative;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            padding: 10px;
            background: rgb(250,250,250);
        }
    }
}

/*Layout*/
.temp_flex {
    padding: 15px;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    background: #fff;
    box-shadow: $side_nav_shadow;
    & > div {
        &:nth-child(1) {
            height: 100%;
            max-height: 100%;
            max-width: 300px;
        }
        &:nth-child(2) {
            width: 100%;
        }
    }
}
.flex {
    display: flex;
    &.justify_content_center {
        justify-content: center;
    }
    &.justify_content_space_between {
        justify-content: space-between;
    }
    &.align_items_center {
        align-items: center;
    }
}
.flex_column {
    display: flex;
    height: 100%;
    flex-direction: column;
    max-height: 100%;

    .row_1 {
        flex: 1;
    }
    .row_2 {
        flex: 1;
    }
    .row_3 {
        flex: 1;
    }
}
.flex_row {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    .col_1 {
        flex: 1;
    }
    .col_2 {
        flex: 2;
    }
    .col_3 {
        flex: 3;
    }
}
.padding {
    padding: 20px;
    &.padding_10 {
        padding: 10px;
    }
}
.page {
    height: 100%;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    position: relative;
}
.scrollX {
    overflow-x: scroll;
    width: 200vh !important;
    max-width: none;
}
.stretch_width {
    min-width: 100%;
}
.margin_center {
    margin: 0 auto;
}
.absolute_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.max-w-100 {
    max-width: 100px;
}

/*Top Bar*/
.top_bar {
    display: flex;
    justify-content: space-between;
    margin: 10px;

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .left {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/*Rows */
.rows_wrapper {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.row {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $background_color_primary;
    margin: 6px 0px 6px 0px;
    border-radius: $border-radius;
    box-shadow: $row_shadow;
    padding: 15px;
    transition: all $transition_speed;
    color: $text_color_dark_soft;

    &:not(.header):hover {
        box-shadow: $row_shadow_hover;
        color: $text_color_dark;
        z-index: 2;
    }

    &.default {
        cursor: default;
    }

    //Normal Row
    &.item {
        & > div {
            flex: 1;
            font-size: $font_size_standard;
        }
    }

    //Header Row
    &.header {
        cursor: default;
        width: 100%;
        margin-bottom: 0px;
        height: 50px;
        & > div {
            flex: 1;
            text-transform: uppercase;
            color: $text_color_dark_soft;
            transition: all $transition_speed;
            cursor: pointer;

            svg {
                //For sort icon
                margin-left: 10px;
            }

            &:hover {
                color: $text_color_dark;
            }
        }
    }
}


/* Text */
.text_align_left {
    text-align: left !important;
}
.text_align_right {
    text-align: right !important;
}
.text_align_center {
    text-align: center !important;
}

/*Headers*/
.title {
    margin: 0px;
    max-width: 400px;
    text-align: center;
    transition: all $transition_speed;
    color: $text_color_dark_soft;
    text-transform: uppercase;
    font-size: 30px;
    cursor: default;

    &.form_title {
        margin: 20px auto;
        width: 100%;
        text-align: center;
    }
}

/*Buttons and Links*/
.button_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.button {
    position: relative;
    display: block;
    text-align: center;
    text-transform: uppercase;
    padding: 8px 20px;
    white-space: nowrap;
    border-radius: $border_button_radius;
    cursor: pointer;
    margin: 10px;
    transition: all $transition_speed;
    font-size: $font_size_button;
    outline: none;

    &:active {
        outline: none;
    }

    &.primary {
        background: $color_primary;
        color: $text_color_light;
    }
    &.delete {
        background: $color_error;
        color: $text_color_light;
    }
    &.outline {
        background: $text_color_light;
        border: 1px solid $color_primary;
        color: $color_primary;

        &:hover {
            color: $text_color_light;
            background: $color_primary;
        }
    }
    &.checkout {
        background: $text_color_light;
        border: 1px solid $orange;
        color: $orange;

        &:hover {
            color: $text_color_light;
            background: $orange;
        }
    }
    &.suspend {
        background: $gold;
        color: $text_color_light;
    }
    &.unsuspend {
        background: $green;
        color: $text_color_light;
    }
    &.upload {
        background: $gold;
        color: $text_color_light;
        width: 180px;
    }
    &.cancel {
    }
    &.secondary {
        color: $text_color_dark_soft;
    }
    &.slide_next {
        position: absolute;
        bottom: 20px;
        right: 20px;

        &.left {
            right: 130px;
        }
        &.right {
        }
    }
    &.slide_previous {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    &.back {
        background: $gold;
        color: $text_color_light;
        &:hover {
            svg {
                transition: all $transition_speed;
                transform: translateX(-5px);
            }
        }
    }
    &.next {
        svg {
            margin-left: 5px;
        }
        &:hover {
            svg {
                transition: all $transition_speed;
                transform: translateX(5px);
            }
        }
    }
    &.form_button {
        //the reason this isn't inside the form is so the form can scroll, but the button is stationary under the form
        margin: 30px 10px;
    }
    &.row_button {
        background: $cyan;
        color: $text_color_light;
    }
    &.center {
        margin: 0 auto;
        width: 100%;
        max-width: 250px;
    }
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
            filter: brightness(1);
        }
    }
    svg {
        //icons in buttons
        margin-right: 5px;
    }
}
a {
    font-size: $font_size_standard;
    &.link {
        color: $blue;
        &:hover {
            text-decoration: underline !important;
        }
    }
}

/*Forms*/
form {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding: 20px 40px;
    min-width: 500px;

    .form_outline_error:not(.form_checkbox_wrapper) {
        border: 2px solid $red !important;
    }

    .form_input_error {
        display: inline-block;
        margin-left: 5px;
        color: $red;
        font-size: $font_size_small;
    }

    .form_group {
        display: flex;
        margin: 10px 0px;
        cursor: pointer;
        width: 100%;
        max-width: 100%;

        & > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            color: $text_color_dark_soft;

            &:not(:nth-child(1)) {
                margin-left: 10px;
            }
        }

        .form_checkbox_wrapper {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            & > label {
                margin: 15px 0 10px 0;
                font-weight: 800;
            }

            .form_input_error {
                font-weight: 500 !important;
            }

            &.form_outline_error .form_checkbox {
                label {
                    color: $red;
                }
            }

            .form_checkbox_container {
                display: flex;
                justify-content: flex-start;
                cursor: default;
                .form_checkbox {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &:not(:nth-child(1)) {
                        margin-left: 20px;
                    }

                    input {
                        border: none;
                        cursor: pointer;
                    }
                    label {
                        cursor: pointer;
                        margin-bottom: 0px;
                        padding-right: 5px;
                    }
                }
            }
        }

        label {
            color: $text_color_dark_soft;
            margin-bottom: 5px;
            font-size: $font_size_standard;

            &.disabled {
                opacity: 0.5;
            }
        }

        input[type="radio"] {
            border: 1px solid $border_color_primary !important;
            min-width: $font_size_standard;
            min-height: $font_size_standard;
        }

        input:not([type="checkbox"]),
        select,
        textarea,
        .multi-select > .dropdown-container {
            width: 100%;
            border-radius: 5px;
            border: 2px solid transparent;
            padding: 10px;
            box-shadow: $form_input_shadow;
            outline: none;
            transition: all $transition_speed;
            color: $text_color_dark_soft;

            &:focus {
                border: 2px solid $text_color_dark_soft;

                &::placeholder {
                    color: $placeholder_color_hover;
                }
            }
            &::placeholder {
                color: $placeholder_color;
                transition: all $transition_speed;
            }
            &:hover {
                box-shadow: $form_input_shadow_hover;
                &::placeholder {
                    color: $placeholder_color_hover;
                }
            }
            &.disabled {
                opacity: 0.5;
                &:hover {
                    box-shadow: $form_input_shadow;
                    border: 2px solid transparent;
                    cursor: not-allowed;
                }
                &:focus {
                    box-shadow: $form_input_shadow;
                    border: 2px solid transparent;
                }
                &::placeholder {
                    color: $text_color_light_soft;
                }
            }
        }

        .multi-select > .dropdown-container {
            padding: 0px;
        }

        /*HIDE ARROWS ON NUMBER INPUTS*
            /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
}
.qty_wrapper {
    .qty {
        width: 45px;
        padding: 5px;
        border: none;
        border: 1px solid $border_color_primary;
        font-size: 16px;
    }
}

/* Loader*/
.loader_fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(20, 20, 20, 0.05);
    z-index: $z_loader;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Leaflet */
.leaflet-draw.leaflet-control {
    & {
        display: none;
    }
    &:nth-child(2) {
        display: block !important;
    }
}
.leaflet-control-layers-overlays {
    & > label:first-child {
        display: none;
    }
}

/* Search Bar */
.search_bar {
    margin: 0 auto;
    margin-right: 10px;
    max-width: 100%;
    &.margin_left {
        margin-left: 25px;
    }
    input {
        width: 100%;
        border: 2px solid $color_gray;
        border-radius: $border-radius;
        padding: 5px;
        box-shadow: $form_input_shadow;
        outline: none;
        transition: all $transition_speed;
        color: $text_color_dark_soft;

        &:focus {
            border: 2px solid $text_color_dark_soft;
            &::placeholder {
                color: $placeholder_color_hover;
            }
        }
        &::placeholder {
            color: $placeholder_color;
            transition: all $transition_speed;
        }
        &:hover {
            box-shadow: $form_input_shadow_hover;
            &::placeholder {
                color: $placeholder_color_hover;
            }
        }
    }
}


/* CSS SPECIFIC STYLING */

/*Margin*/
/*#region*/

/*Margin Right*/
/*#region*/
.mr-5 {
    margin-right: 5px;
}
.mr-10 {
    margin-right: 5px;
}
.mr-15 {
    margin-right: 5px;
}
.mr-25 {
    margin-right: 5px;
}
.mr-50 {
    margin-right: 5px;
}
.mr-100 {
    margin-right: 5px;
}
.mr-125 {
    margin-right: 5px;
}
.mr-150 {
    margin-right: 5px;
}
.mr-175 {
    margin-right: 5px;
}
.mr-200 {
    margin-right: 5px;
}
/*#endregion*/

/*Margin Left*/
/*#region*/