@import '../../../styles/main';

.modal_foreground {
    position: fixed;
    max-height: 90vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: $soft_shadow;
    z-index: $z_modal_foreground;

    // Adjustments for smaller screens
    @media (max-width: 600px) {
        width: 90%;
        max-height: 80vh;
        overflow-y: auto;
        top: 5%;
        left: 5%;
        transform: translate(0, 0);
    }

    .modal_text {
        margin: 20px;

        @media (max-width: 600px) {
            margin: 10px;
        }
    }

    .close_modal_btn {
        position: absolute;
        top: -6px;
        right: -6px;
        background: $color_primary;
        color: $text_color_light;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        overflow: hidden;
        transition: all $transition_speed;
        cursor: pointer;

        &:hover {
            filter: brightness(1.1);
        }
    }

    &.show {
        animation: fadeIn $animation_speed forwards;
    }

    &.show-slide {
        animation: slideOpen $animation_speed forwards;
    }
}

.modal_background {
    opacity: 0;
    background: rgba(80, 80, 80, .0);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $z_modal_background;

    &.show {
        animation: fadeIn $animation_speed forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideOpen {
    0% {
        opacity: 0;
        width: 0px;
    }

    50% {
        width: 90%;
    }

    100% {
        opacity: 1;
        width: 90%;
    }
}