@import '../../../styles/variables';

.variant_product_row {
    position: relative;
    display: flex;
    .delete {
        top: 20px;
        position: absolute;
        color: $red;
        &:hover {
            filter: brightness(1.1);
            cursor: pointer;
        }
    }
}