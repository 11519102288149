@import "../../../styles/variables";

.form_slider {
    height: 100%;

    form {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;
        max-width: 100%;
        padding: 0px;

        .form_slide {
            position: absolute;
            padding: 15px;
            display: flex;
            height: 100%;
            width: 100%;
            transition: all $animation_speed;
                overflow: hidden;

            .form_slide_content {
                display: none;
                height: calc(100% - 60px);
                flex: 1;
                width: 100%;
                overflow-y: scroll;

            .scrollX {
                padding: 10px 0px;
                input {
                    min-width: 150px;
                }
                select {
                    min-width: 120px;
                }
            }
            }


            .form_group {
                margin: 40px 0px;
            }
        }
    }

    &.slide-1 {
        .form_slide {
            &:nth-child(1) {
                transform: translateX(0);
                .form_slide_content {
                    display: block;
                }
            }
            &:nth-child(2) {
                transform: translateX(100%);
            }
            &:nth-child(3) {
                transform: translateX(200%);
            }
            &:nth-child(4) {
                transform: translateX(300%);
            }
            &:nth-child(5) {
                transform: translateX(400%);
            }
        }
    }

    &.slide-2 {
        .form_slide {
            &:nth-child(1) {
                transform: translateX(-100%);
            }
            &:nth-child(2) {
                transform: translateX(0);
                .form_slide_content {
                    display: block;
                }
            }
            &:nth-child(3) {
                transform: translateX(100%);
            }
            &:nth-child(4) {
                transform: translateX(200%);
            }
            &:nth-child(5) {
                transform: translateX(300%);
            }
        }
    }

    &.slide-3 {
        .form_slide {
            &:nth-child(1) {
                transform: translateX(-200%);
            }
            &:nth-child(2) {
                transform: translateX(-100%);
            }
            &:nth-child(3) {
                transform: translateX(0);
                .form_slide_content {
                    display: block;
                }
            }
            &:nth-child(4) {
                transform: translateX(100%);
            }
            &:nth-child(5) {
                transform: translateX(200%);
            }
        }
    }

    &.slide-4 {
        .form_slide {
            &:nth-child(1) {
                transform: translateX(-300%);
            }
            &:nth-child(2) {
                transform: translateX(-200%);
            }
            &:nth-child(3) {
                transform: translateX(-100%);
            }
            &:nth-child(4) {
                transform: translateX(0);
                .form_slide_content {
                    display: block;
                }
            }
            &:nth-child(5) {
                transform: translateX(100%);
            }
        }
    }

    &.slide-5 {
        .form_slide {
            &:nth-child(1) {
                transform: translateX(-400%);
            }
            &:nth-child(2) {
                transform: translateX(-300%);
            }
            &:nth-child(3) {
                transform: translateX(-200%);
            }
            &:nth-child(4) {
                transform: translateX(-100%);
            }
            &:nth-child(5) {
                transform: translateX(0);
                .form_slide_content {
                    display: block;
                }
            }
        }
    }
}
