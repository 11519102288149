@import '../../../styles/variables';

.summary_wrapper {
    margin: 10px;
    display: flex;
    .summary_item {
        flex: 1;
        max-width: 200px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .summary_item_title {
            color: $text_color_dark_soft;
        }
        .summary_item_value {
            color: $color_primary;
            font-size: 20px;
        }
        &:first-child::after {
            display: none;
        }
    }
}