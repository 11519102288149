@import '../../../styles/variables';

.tabs {
    .tab {
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid transparent;

        &.selected {
            border-bottom: 1px solid $border_color_primary;
        }

        & > div, & > h2 {
            position: relative;
            z-index: 2;
        }

        &.no_value {
            padding: 15px 30px;
            margin: 5px;
            max-height: 20px;
            border: 1px solid $gold;
            border-radius: $border_button_radius;
            transition: all $transition_speed;

            &.selected {
                background: $gold;
                & > div {
                    color: $text_color_light !important;
                }
            }

            &::after {
                display: none;
            }
        }

    }
}