@import "../../../styles/variables";

.create_product_page {
    .variant {
        display: inline-block;
        background: $cyan;
        color: $text_color_light;
        border-radius: $border-radius;
        box-shadow: $row_shadow;
        padding: 15px;
        margin: 0px;
        svg {
            display: inline-block;
            margin-left: 5px;
            color: $text_color_light;
        }
    }
    .delete {
        display: inline;
        width: 40px;
        margin-top: 5px;
        color: $text_color_dark_soft;
        font-size: $font_size_small;
        opacity: .6;

        &:hover {
            color: $red;
            opacity: .8;
        }
    }

    .variation_warning {
        color: $text_color_dark_soft;
    }
    .checkbox_wrapper {
        display: flex;
        max-width: 100px;
        flex-direction: column;
        label {
            max-width: 100px;
        }
        input {
            width: 100%;
            min-width: 0px !important;
        }
    }
}
