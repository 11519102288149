// @import './variables';
// @import './common';

/*Colors */
/****BRAND COLORS*****/
$blue: rgb(22,109,255);
$green: rgb(124,187,46);
$gold: rgb(255,189,28);
$orange: rgb(255,132,35);;
$red: rgb(255,0,0);
$pink: rgb(255,47,154);
$purple: rgb(177,0,234);
$cyan: rgb(48,205,155);
/****END BRAND COLORS*****/
$color_gray: rgb(198, 198, 198);
$color_undefined: rgb(198, 198, 198);
$color_primary: rgb(22,109,255);
$color_error: rgb(224, 70, 70);
$color_disabled: rgba(30,30,30,.3);
$text_color_dark: rgba(0,0,0,1);
$text_color_dark_soft: rgba(0,0,0,.6); 
$text_color_light: rgba(255,255,255,1);
$text_color_light_soft: rgba(255,255,255,.5);
$border_color_primary: rgba(30,30,30,.2);
$button_border_color_primary: rgba(31, 74, 193, 1);
$placeholder_color: rgba(30,30,30,.2);
$placeholder_color_hover: rgba(30,30,30,.5);
$navigation_button_color_hover: rgba(194, 205, 238, 0.2);

$background_color_primary: #fff;
$background_color_secondary: rgb(253, 253, 253);


/* Color Classes*/
/*#region*/
.blue {
    &.background {
        background: $blue !important;
    }
    &.text {
        color: $blue !important;
    }
}
.green {
    &.background {
        background: $green !important;
    }
    &.text {
        color: $green !important;
    }
}
.gold {
    &.background {
        background: $gold !important;
    }
    &.text {
        color: $gold !important;
    }
}
.orange {
    &.background {
        background: $orange !important; 
    }
    &.text {
        color: $orange !important;
    }
}
.red {
    &.background {
        background: $red !important;
    }
    &.text {
        color: $red !important;
    }
}
.pink {
    &.background {
        background: $pink !important;
    }
    &.text {
        color: $pink !important;
    }
}
.purple {
    &.background {
        background: $purple !important;
    }
    &.text {
        color: $purple !important;
    }
}
.cyan {
    &.background {
        background: $cyan !important;
    }
    &.text {
        color: $cyan !important;
    }
}
/*#endregion*/

/* Font */
$font_size_button: 14px;
$font_size_navigation: 16px;
$font_size_standard: 14px;
$font_size_subtitle: 24px;
$font_size_small: 12px;

/* Shadows */
$soft_shadow: rgba(0, 0, 0, 0.2) 0px 24px 38px 3px;
$row_shadow: rgba(50,50,50,.1) 2px 3px 10px;
$row_shadow_hover: rgba(50,50,50,.15) 4px 5px 10px;
$side_nav_shadow: rgba(30, 30, 30, 0.1) 5px 0px 15px 3px;
//$form_input_shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px 0px; //original
$form_input_shadow: rgba(30,30, 30, .1) 2px 2px 10px ;
//$form_input_shadow_hover: rgba(0, 0, 0, 0.11) 2px 14px 10px 0px; //original
$form_input_shadow_hover: rgba(30,30, 30, .15) 4px 4px 15px ;

/* Borders */
$border_radius: 0px;
$border_button_radius: 0px;
$border_active_width: 0px;
$border_round: 20px;

/* Transitions and Animations*/
$transition_speed: 200ms;
$animation_speed: 400ms; //currently used for fading in modal

/* Z Index */
$z_loader: 403;
$z_modal_foreground: 402;
$z_modal_background: 401;
$z_sidecart: 97;
$z_sidecart_background: 96;
$z_select_dropdown: 94;
$z_navbar: 95;