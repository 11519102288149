@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400&display=swap");

@font-face {
    font-family: "Gotham";
    src: local("Gotham"), url("./fonts/GothamBook.ttf") format("opentype");
}

@layer components {
    .btn {
        @apply cursor-pointer uppercase rounded-sm py-2 px-6 text-center text-xs shadow-md flex justify-center items-center hover:shadow-lg duration-theme;
    }

    .input {
        @apply px-3 py-2 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm outline-none focus:outline-none ring-1 ring-gray-300 focus:ring-blue-400 focus:ring-2 w-full duration-500;
    }

    .calc-nav {
        height: calc(100% - 50px);
    }

    .inset-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .overlay-shadow-bottom {
        background: linear-gradient(to bottom, rgba(30, 30, 30, 0.3), rgba(30, 30, 30, 0.25) 30%, rgba(30, 30, 30, 0.02));
    }
}


.dropdown:hover .dropdown-menu {
    max-height: 1000px !important;
    transition: all 600ms;
}

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    /* font-family: "Gotham"; */
    font-family: "lato";
}

ul {
    list-style: none;
}
