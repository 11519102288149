@import "../../../styles/variables";

.create_order_page {
    .catalog {
        padding-top: 20px;
        overflow-y: auto;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 20px;
        grid-template-areas:
            ". . . . ."
            ". . . . ."
            ". . . . .";
        display: grid;
        .catalog_item {
            padding: 10px;
            display: flex;
            flex-direction: column;
            box-shadow: $row_shadow;
            border-radius: $border-radius;
            transition: $transition_speed;
            opacity: 0;
            transform: translateY(40px);
            cursor: pointer;
            animation: slide-in $animation_speed forwards;

            &:hover {
                box-shadow: $row_shadow_hover;

                .button {
                    color: $text_color_light;
                    background: $color_primary;

                    svg {
                        transition: all $transition_speed;
                        transform: translateX(5px);
                    }
                }
            }

            & > div {
                margin: 5px 0px;
            }
            .title_container {
                flex: 2;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .money {
                flex: 1;
                text-align: center;
                font-weight: 800;
                color: $color_primary;
            }
            .button_container {
                flex: 2;
            }
        }
    }
    .order_modal_wrapper {
        width: 90vw;
        max-width: 600px;
        padding: 25px;

        & > div {
            margin: 10px 0px;
        }

        .product_cost {
            display: block;
            margin: 10px 0px;
            color: $green;
            text-align: center;
            font-size: 26px;
        }
        .product_title {
            width: 100%;
            max-width: none;
            font-size: 30px;
            color: $text_color_dark_soft;
            text-align: center;
        }
        .product_description {
            padding: 0px 10px;
            margin: 0px 5px;
            max-height: 220px;
            overflow-y: scroll;
            line-height: 20px;
            color: $text_color_dark_soft;
        }
        .product_info {
            display: flex;
            cursor: default;
            & > div {
                flex: 1;
                background: $color_primary;
                color: $text_color_light;
                border-radius: $border_round;
                padding: 7px;
                margin: 10px;
                text-align: center;
            }
        }
        .product_variant_dropdown_wrapper {
            display: flex;
            .product_variant_dropdown {
                padding: 5px;
                margin: 10px;
                label {
                    margin-right: 5px;
                }
                select {
                    padding: 5px;
                    border: none;
                    border-bottom: 2px solid $color_primary;
                    font-size: 15px;
                }
            }
        }
        .product_button_qty_wrapper {
            display: flex;
            align-items: center;
            .product_qty {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                margin: 10px;
                label {
                }
                input {
                    max-width: 50px;
                    padding: 5px;
                    border: none;
                    font-size: 18px;
                }
            }
            .product_button {
                flex: 3;
                margin: 0px;
            }
            .add_to_cart_total {
                display: flex;
                justify-content: center;
                align-items: center;
                    margin-right: 10px;
                label{
                    margin-right: 5px;
                }
                span {
                    color: $green;
                }
            }
        }
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@for $i from 1 through 50 {
    .catalog .catalog_item:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.05}s;
    }
}
