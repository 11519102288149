@import "../../styles/variables";

/*Variables*/
$navbar_width_open: 190px;
$navbar_width_closed: 56px;
/*end Variables*/

.navbar_block {
    content: "";
    transition: $transition_speed;
    &.open {
        min-width: $navbar_width_open;
    }
    &.close {
        min-width: $navbar_width_closed;
    }
}
.navbar {
    z-index: $z_navbar;
    position: fixed;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: $side_nav_shadow;
    padding: 0px;
    justify-content: space-between;
    background-color: $background_color_primary;
    transition: all $transition_speed;
    overflow-y: auto;
    overflow-x: hidden;

    .navbar_logo {
        cursor: pointer;
    }

    &.hide {
        width: $navbar_width_closed;
        min-width: $navbar_width_closed;

        .version {
            display: none;
        }

        .navbar_top {
            width: 100%;
        }
        .navbar_bottom {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            h6 {
                display: none;
            }
            button {
                display: none;
            }
            svg {
                display: block;
                color: $text_color_dark_soft;
                cursor: pointer;
                &:hover {
                    color: $color_primary;
                }
            }
        }
        .navbar_logo {
            padding: 0px 5px;
            border-bottom: 1px solid $border_color_primary;
            img {
                width: 100%;
                height: 60px;
                object-fit: contain;
            }
        }

        .nav_list {
            height: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            a {
                color: $text_color_dark_soft;
                transition: $transition_speed;
                border-top-right-radius: $border_radius;
                border-bottom-right-radius: $border_radius;
                border-left: $border_active_width solid transparent;
                font-size: $font-size_navigation;
                margin: 2px 0px;
                padding: 8px 10px;
                width: 100%;
                text-align: center;

                span {
                    display: none;
                }

                svg {
                    filter: opacity(0.7);
                }
                &:hover {
                    color: $color_primary;
                    background: $navigation_button_color_hover;
                    border-left: $border_active_width solid $color_primary;
                }
            }
        }
    }

    &.open {
        width: $navbar_width_open;
        min-width: $navbar_width_open;
        padding: 0px 0px;

        .navbar_top {
            width: 100%;
        }
        .navbar_bottom {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // padding: 60px 16px;
            padding-bottom: 5px;
            width: 100%;

            svg {
                display: none;
            }

            h6 {
                font-weight: 400;
                color: $text_color_dark_soft;
                font-size: $font-size_navigation;
                padding-top: 40px;
                margin: 16px 0px;
                width: 100%;
                text-align: center;
                border-top: 1px solid $border_color_primary;
            }
        }

        .navbar_logo {
            padding: 20px 0px;
            margin: 0px 16px;
            border-bottom: 1px solid $border_color_primary;

            img {
                width: 100%;
                height: 100px;
                object-fit: contain;
            }
        }

        .nav_list {
            width: 100%;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0px 10px;

            a {
                white-space: nowrap;
                color: $text_color_dark_soft;
                margin: 2px 0px;
                padding: 8px 8px;
                transition: $transition_speed;
                border-top-right-radius: $border_radius;
                border-bottom-right-radius: $border_radius;
                border-left: $border_active_width solid transparent;
                font-size: $font-size_navigation;
                svg {
                    margin-right: 10px;
                    width: 30px;
                    filter: opacity(0.7);
                }
                &:hover {
                    color: $color_primary;
                    background: $navigation_button_color_hover;
                    border-left: $border_active_width solid $color_primary;
                }
            }
        }
    }

    .toggle_nav {
        background: $background_color_primary;
        position: absolute;
        left: 100%;
        top: 50%;
        height: 120px;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-50%);
        color: $text_color_dark_soft;
        font-size: 12px;
        padding: 1px;
        cursor: pointer;

        svg {
            transform: rotate(180deg);
            transition: all $transition_speed;
            filter: opacity(0.5);
        }

        &.close {
            height: 50vh;
            svg {
                transform: rotate(0deg);
                filter: opacity(1);
            }
        }
    }
}

@media screen and (max-width: 1275px) {
    .navbar_block {
        &.open {
            min-width: $navbar_width_closed;
        }
        &.close {
            min-width: $navbar_width_closed;
        }
    }
}
