@import "../../styles/_main";

.catalog_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 6px;

    .catalog_row {
        display: flex;
        border-bottom: 1px solid $border_color_primary;
        padding: 5px;
        cursor: pointer;
        transition: all 300ms;

        > div {
            flex: 1;
            display: flex;
            align-items: center;

            &.description {
                flex: 2;
            }
        }

        &.catalog_filters {
            cursor: default;

            &:hover {
                background: none;
            }
        }
        .catalog_item {
        }

        &:hover {
            background: rgba(50, 50, 50, 0.1);
        }
    }
}
