@import "../../../styles/variables";

.accordion_wrapper {
    padding: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: $row_shadow;
    margin-left: 25px;
    color: $text_color_dark_soft;
    transition: all $transition_speed;
    cursor: default;

    &:hover {
        box-shadow: $row_shadow_hover;
    }


    .accordion_tab {
        transition: all $transition_speed;
        max-height: 0px;
        overflow: hidden;
    }
    .tab_title {

        &:hover {
            color: $text_color_dark;
        }
        &.level_1 {
            cursor: pointer;
            padding: 10px 0;
        }
        &.level_2 {
            cursor: pointer;
            font-size: 12px;
            padding: 5px 0;
        }
        &.level_3 {
            font-size: 12px;
            padding: 5px 0;
        }

        &.open + .accordion_tab {
            max-height: 200px;
            overflow-y: auto;
        }
    }
    .tab_content {
        cursor: pointer;
        display: flex;
        padding: 5px;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 12px;
        }
    }
    .tab_icon {
        // svg {
        //     color: $text_color_dark_soft;
        //     cursor: pointer;
        //     margin: 2px 6px;
        //     font-size: 12px;
        //     &:hover {
        //         color: $color_primary;
        //         transform: scale(1.1);
        //     }
        // }
    }
}
