@import "../../../styles/variables";

.side_cart_wrapper {
    position: fixed;
    right: 0px;
    top: 0;
    bottom: 0;
    width: 275px;
    background: $background_color_primary;
    box-shadow: $side_nav_shadow;
    min-height: 100vh;
    height: 100vh;
    z-index: $z_sidecart;
    transition: all $animation_speed;
    animation-duration: 1000ms;
    opacity: 0;
    transform: translateX(275px);

    &.show {
        animation: slide-in $animation_speed forwards;
    }

    .sidebar_total {
        position: absolute;
        bottom: 100px;
        width: 100%;
        text-align: center;
        color: $text_color_dark_soft;
        font-size: 20px;
    }

    .close_side_cart_wrapper {
        background: $orange;
        padding: 5px;
        cursor: pointer;
        transition: all $transition_speed;

        &:hover {
            filter: brightness(1.1);
        }

        .close_side_cart {
            color: $text_color_light;
            position: absolute;
            right: 8px;
            top: 5px;
        }

        .side_cart_title {
            text-align: center;
            padding: 5px;
            color: $text_color_light;
            font-size: 18px;
        }
    }

    .products_wrapper {
        position: relative;
        overflow-y: auto;
        max-height: calc(100% - 200px);

        .product_item {
            position: relative;
            transform: translateX(275px);
            min-height: 100px;
            margin: 15px 5px;
            padding: 5px;
            background: $background_color_secondary;
            border-radius: $border-radius;
            box-shadow: $form_input_shadow;
            animation: slide-in $animation_speed forwards;
            display: flex;
            align-items: center;
            justify-content: center;

            .delete_item {
                position: absolute;
                top: 0px;
                right: 4px;
                color: $text_color_dark_soft;
                transition: $transition_speed;
                cursor: pointer;
                &:hover {
                    color: $red;
                }
            }

            .product_info {
                flex: 2;
                .product_title {
                    font-size: 20px;
                    color: $text_color_dark_soft;
                }
                .product_price {
                    color: $green;
                    font-size: 24px;
                }
                .product_variants_wrapper {
                    .product_variant {
                        font-size: 12px;
                        .variant_name {
                            color: $text_color_dark_soft;
                            margin-right: 5px;
                        }
                        .variant_value {
                        }
                    }
                }
            }
            .qty_wrapper {
                flex: 1;
                .qty {
                }
            }
        }
    }

    .checkout_process_button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 20px;
        width: 80%;
        margin: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@for $i from 1 through 10 {
    .products_wrapper .product_item:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1}s;
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateX(275px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.side_cart_background {
    position: fixed;
    z-index: $z_sidecart_background;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(30, 30, 30, 0.3);

    &.show {
        animation: fadeIn $animation_speed forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.side_cart_min {
    position: fixed;
    bottom: 40px;
    right: 40px;
    height: 50px;
    width: 50px;
    background: $orange;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $side_nav_shadow;
    z-index: $z_sidecart;
    cursor: pointer;

    &:hover {
        filter: brightness(1.1);
    }
}
